<template>
  <div v-if="loaded && slide" class="resting-slide-container">
    <slide-heading :html-heading="slide.heading" />
    <slide-image-and-description :slide="slide" :allow-images="true" />
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <b-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
export default {
    name: 'RestingSlide',
    components: {
      SlideImageAndDescription: () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
      SlideHeading:             () => import('@/components/StagelineV2/shared/SlideHeading'),
    },
    props: {
      slide: null,
    },
    data() {
      return {
        loaded: false,
      }
    },
    computed: {
      imageUrl() {
        return this.slide.image_urls[0]
      },
    },
    mounted() {
      this.loaded = true
    },
  }
</script>
<style scoped lang="scss">
  .resting-slide-container {
    .image-vert {
      text-align: center;
      height: 25em;
      img {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .resting-slide-container {
      width: 100%;
      .image-vert {
        height: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
</style>
